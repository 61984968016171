import React, { useState, useEffect, useContext } from 'react';

import './CharsheetMobile.css'

import { UserDataContext } from "../../App.js";

import FlexiGrid2 from '../common/Flexigrid2/Flexigrid2.js';

import LoadSaveButton from './MobileLoadSaveButton.js';

import Spell from '../../components/Spell';

import weaponGrandTotalImage from '../../assets/img/grand-total-2.png';

import sample_data_flat from '../../components/Charsheet0/helpers/sample_data_flat.js'


// ----------------------------------------------------------------------------------
//                         Description
//
//     CharsheetMobile is intended as the main html page for mobile
//
//     It will have a mobile header, a character section, and a details section
//
//     flatten.js has the text field names
//
//     (c) DXA Development 2024
// ----------------------------------------------------------------------------------

function CharsheetMobile(props) {

    // ------------ parameters ---------------
    let startData = sample_data_flat
    const INITIAL_MOBILE_SCROLL = 262;

    // ------ inherited state variables ------

    // props.state = globalstate = [ currentChar, setChar, loggedIn, layout, setLayout, currentSpellArray, currentSpellDataArray ]    

    var currentChar = props.state[0];
    var setChar = props.state[1];

    const [ currentSpell, setCurrentSpell] = props.state[5]
    const [ currentSpellData, setCurrentSpellData ] = props.state[6];

    const spellCheckArray = props.spellCheckArray;

    console.log('CHARSHEET MOBILE:', currentChar)

    const data = props.data;
    const setData = props.setdata;
    var spellUpdaters = props.spellUpdaters;

    // charID is used to ID the main div, which will be id="{charID}-div"
    const thisCharID = props.charID || '0'

    // -------- local state variables --------
    // const [ currentChar, setChar ] = useState( [ props.state[0], props.state[1] ] );
    var [ charImage, setCharImage ] = useState( process.env.PUBLIC_URL + '/img/char/' + currentChar + '.jpg' );
    const [ editing, setEditing ] = useState(false);
    
    const [ currentSpell3, setCurrentSpell3] = useState('Charmobile Knock');
    const [ currentSpellData3, setCurrentSpellData3 ] = useState({});
    spellUpdaters = [setCurrentSpell, setCurrentSpellData]
    
    const [ firstLoad, setFirstLoad ] = useState(true);
    const [ loadedChar, setLoadedChar ] = useState(currentChar);

    // ------------- event handlers ---------------
    
    const toggleEdit = () => {
        if (!editing) {
            
            // Hide elements with class "editable-link"
            document.querySelectorAll(".editable-link").forEach((element) => {
              element.style.display = "none";
            });
          
            // Show elements with class "editable-input"
            
            setValuesFromButtonsToInputs()

            document.querySelectorAll(".editable-input").forEach((element) => {
              element.style.display = "block"; // Use "block" or "inline" based on your CSS requirements
            });
            
          } else {
            // Show elements with class "editable-link"
            document.querySelectorAll(".editable-link").forEach((element) => {
              element.style.display = "block"; // Use "block" or "inline" based on your CSS requirements
            });
          
            // Hide elements with class "editable-input"
            document.querySelectorAll(".editable-input").forEach((element) => {
              element.style.display = "none";
            });
          }

        setEditing(!editing);
        }

    // what happens when you click on a Spell Button (Spell1, ...)     
    const handleSpellButtonClick = () => {
        console.log('Toggle edit')
        toggleEdit();

      }

    function setValuesFromButtonsToInputs() {
        for (let i = 11; i <= 55; i++) {
          const button = document.getElementById(`mySp${i}-but`);
          const input = document.getElementById(`mySp${i}`);
          
          if (button && input) {
            input.value = button.textContent;
          }
        }
      
      for (let i = 1; i <= 6; i++) {
        let button = document.getElementById(`myMag${i}-but`);
        let input = document.getElementById(`myMag${i}`);          
        if (button && input) { input.value = button.textContent; }

        button = document.getElementById(`myOth${i}-but`);
        input = document.getElementById(`myOth${i}`);          
        if (button && input) { input.value = button.textContent; }

        button = document.getElementById(`myPot${i}-but`);
        input = document.getElementById(`myPot${i}`);          
        if (button && input) { input.value = button.textContent; }

        button = document.getElementById(`myArm${i}-but`);
        input = document.getElementById(`myArm${i}`);          
        if (button && input) { input.value = button.textContent; }
        

        button = document.getElementById(`myWeap${i}-but`);
        input = document.getElementById(`myWeap${i}`);          
        if (button && input) { input.value = button.textContent; } 
        
      }

      }

    // ------------- useEffect ---------------

    useEffect( () => {

        async function DoSomething () {  

            console.log('CharsheetMobile UPDATE ', data )

            // if this is a new character load, then scroll to the Combat section
            if ( firstLoad || ( currentChar !== loadedChar ) ) {
              var scrollableDiv = document.getElementById("charsheet-scroll");
              scrollableDiv.scrollTop = INITIAL_MOBILE_SCROLL;
              setFirstLoad(false);
            }

            setCharImage(process.env.PUBLIC_URL + '/img/char/' + currentChar + '.jpg');

            setLoadedChar( currentChar );
            
        }

        DoSomething();

    }, [ data, currentChar ]) ; //useEffect



    // ---------- define the grid for this layout ----------

    const spellOptions = {'spellUpdaters': spellUpdaters, 'handleClick': handleSpellButtonClick }
    
    // majority of cells. see cells_html for special cells like spells

    const StatStart = 8;
    const WeapyStart = 16;
    const SavyStart = 22;
    const ACyStart = 26;
    const OthStart = 45;
    const FollowerStart = 53;
    const SpellStart = 64;

    const cells_layout_main = { 
      // name:       [1, 7, 1, 2],

      ClassTxt:   [1, 3, 2, 3],  myClass:    [3, 7, 2, 3],
      LvlTxt:     [1, 3, 3, 4],  myLevel:    [2, 5, 3, 4], myXPbonus: [5,7,3,4], 
      XPTxt:      [7, 9, 6, 7],  myXP:       [7, 9, 7, 8],
      NXPTxt:     [5, 7, 6, 7],  myXPnext:   [5, 7, 7, 8],
      GoldTxt:    [7, 9, 4, 5],  myGold:     [7, 9, 5, 6],
      RaceTxt:    [1, 3, 4, 5],  myRace:     [3, 5, 4, 5],
      AliTxt:     [1, 3, 5, 6],  myAlign:    [3, 4, 5, 6],
      DeiTxt:     [5, 7, 4, 5],  myDeity:    [5, 7, 5, 6],

      StrTxt:     [1,2,StatStart,StatStart+1],  myStr:      [2, 4, StatStart,StatStart+1],  myStrBon:   [4, 5, StatStart,StatStart+1],
      IntTxt:     [1,2,StatStart+1,StatStart+2],  myInt:      [2, 4, StatStart+1,StatStart+2],  myIntBon:   [4, 5, StatStart+1,StatStart+2],
      WisTxt:     [1,2,StatStart+2,StatStart+3],  myWis:      [2, 4, StatStart+2,StatStart+3],  myWisBon:   [4, 5, StatStart+2,StatStart+3],
      DexTxt:     [5,6,StatStart,StatStart+1],  myDex:     [6, 8, StatStart,StatStart+1],  myDexBon:   [8, 9, StatStart,StatStart+1],
      ConTxt:     [5,6,StatStart+1,StatStart+2],  myCon:      [6, 8, StatStart+1,StatStart+2],  myConBon:   [8, 9, StatStart+1,StatStart+2],
      ChaTxt:     [5,6,StatStart+2,StatStart+3],  myCha:      [6, 8, StatStart+2,StatStart+3],  myChaBon:   [8, 9, StatStart+2,StatStart+3],

      StatBuffer: [1,9,StatStart+3,StatStart+4],
      

      MovTxt:     [1, 3, 14, 15],   myMov:     [1, 3, 15, 16 ],
      HeiTxt:     [1, 3, 6, 7 ],   myHei:      [3, 5, 6 , 7 ], 
      WeiTxt:     [1, 3, 7, 8 ],   myWei:      [3, 5, 7, 8 ],
      
      HPTxt:      [7, 9, 12, 13], myHPbase:  [7, 9, 13, 14],
      myHPcurr:   [6, 9, 14, 15], myHPnote:  [6, 9, 15, 16],
      ACTxt:      [1, 2, 12, 13], myAC:      [2, 4, 12, 14],
      BaseTxt:    [4, 5, 12, 13], myRear:    [5, 7, 12, 13],
      RearTxt:    [4, 5, 13, 14], mySide:    [5, 7, 13, 14],
      ToHitTxt:   [3, 4, 15, 16], myToHit:   [4, 6, 15, 16],
      NattTxt:    [3, 4, 14, 15], myNatt:    [4, 6, 14, 15],
      
      myBaseAC:   [1, 2, ACyStart, ACyStart+1], 
      myAb1:      [1, 2, ACyStart+1, ACyStart+2],
      myAb2:      [1, 2, ACyStart+2, ACyStart+3], 
      myAb3:      [1, 2, ACyStart+3, ACyStart+4], 
      myAb4:      [1, 2, ACyStart+4, ACyStart+5], 
      myAb5:      [1, 2, ACyStart+5, ACyStart+6], 

      WthTxt: [5, 6, WeapyStart, WeapyStart+1],    WdbTxt: [8, 9, WeapyStart, WeapyStart+1],   WdgTxt:  [6 ,8, WeapyStart, WeapyStart+1],
      myWth1: [5, 6, WeapyStart+1, WeapyStart+2], myWdb1: [8, 9, WeapyStart+1, WeapyStart+2], myWdmg1: [6 ,8, WeapyStart+1, WeapyStart+2],
      myWth2: [5, 6, WeapyStart+2, WeapyStart+3], myWdb2: [8, 9, WeapyStart+2, WeapyStart+3], myWdmg2: [6 ,8, WeapyStart+2, WeapyStart+3],
      myWth3: [5, 6, WeapyStart+3, WeapyStart+4], myWdb3: [8, 9, WeapyStart+3, WeapyStart+4], myWdmg3: [6 ,8, WeapyStart+3, WeapyStart+4],
      myWth4: [5, 6, WeapyStart+4, WeapyStart+5], myWdb4: [8, 9, WeapyStart+4, WeapyStart+5], myWdmg4: [6 ,8, WeapyStart+4, WeapyStart+5],
      myWth5: [5, 6, WeapyStart+5, WeapyStart+6], myWdb5: [8, 9, WeapyStart+5, WeapyStart+6], myWdmg5: [6 ,8, WeapyStart+5, WeapyStart+6],


      myQpo1:     [8, 9, 33, 34],
      myQpo2:     [8, 9, 34, 35], 
      myQpo3:     [8, 9, 35, 36], 
      myQpo4:     [8, 9, 36, 37], 
      myQpo5:     [8, 9, 37, 38], 
      myQpo6:     [8, 9, 38, 39], 

      FolTxt: [1, 9, FollowerStart+0, FollowerStart+1], myFol1: [1, 9, FollowerStart+1, FollowerStart+2], myFol2: [1, 9, FollowerStart+2, FollowerStart+3],
      RacTxt: [7, 9, SpellStart-8, SpellStart-7], myRabi: [1, 9, SpellStart-8, SpellStart],

      SaveTxt: [1, 9, SavyStart, SavyStart+1], SavTxt1: [1, 2, SavyStart+1, SavyStart+2], mySav1: [2, 3, SavyStart+1, SavyStart+2], mySbon1: [3, 4, SavyStart+1, SavyStart+2], SavTxt2: [1, 2, SavyStart+2, SavyStart+3], mySav2: [2, 3, SavyStart+2, SavyStart+3], mySbon2: [3, 4, SavyStart+2, SavyStart+3], 
      SavTxt3: [1, 2, SavyStart+3, SavyStart+4], mySav3: [2, 3, SavyStart+3, SavyStart+4], mySbon3: [3, 4, SavyStart+3, SavyStart+4], 
      SavTxt4: [5, 6, SavyStart+1, SavyStart+2], mySav4: [6, 7, SavyStart+1, SavyStart+2], mySbon4: [7, 8, SavyStart+1, SavyStart+2], 
      SavTxt5: [5, 6, SavyStart+2, SavyStart+3], mySav5: [6, 7, SavyStart+2, SavyStart+3], mySbon5: [7,8, SavyStart+2, SavyStart+3], 
      SavTxt6F: [5, 6, SavyStart+3, SavyStart+4], mySbon6: [6, 9, SavyStart+3, SavyStart+4], mySbon7: [8, 9, SavyStart, SavyStart+1],

      myTsp1: [3, 4, SpellStart+0, SpellStart+1], myNsp1: [4, 5,SpellStart+0, SpellStart+1],
      myTsp2: [7, 8, SpellStart+0, SpellStart+1], myNsp2: [8, 9, SpellStart+0, SpellStart+1], 
      myTsp3: [3, 4, 73, 74], myNsp3: [4, 5, 73, 74], myTsp4: [7, 8, 73, 74], myNsp4: [8, 9, 73, 74], 
      myTsp5: [3, 4, 84, 85], myNsp5: [4, 5, 84, 85],

      AbiTxt: [5, 9, SpellStart+22, SpellStart+23], myAbi: [5,9, SpellStart+23, SpellStart+28],

      AgeTxt: [7,8, OthStart, OthStart+1 ], myAge: [8,9, OthStart, OthStart+1 ]
  };

    // these cells can contain other objects or html as text
    const cells_html = [ 
        // {'name': 'html-test', 'coords': [7,9,4,5], 'code': 'HI MOM' },
        // {'name': 'char-image-mobile', 'coords': [7,9,1,2], 'type': 'img', 'options': {'source': charImage }},
        {'name': 'gTotImg', 'coords': [5, 6, WeapyStart-1, WeapyStart+1], 'type': 'img', 'options': {'source': weaponGrandTotalImage }},

        {'name': 'ArmTxt', 'coords': [2, 9, ACyStart, ACyStart+1], 'type': 'spellButton', 'options': {} },
        {'name': 'myArm1', 'coords': [2, 9, ACyStart+1, ACyStart+2], 'type': 'editableLink-item', 'options': spellOptions },
        {'name': 'myArm2', 'coords': [2, 9, ACyStart+2, ACyStart+3], 'type': 'editableLink-item', 'options': spellOptions },
        {'name': 'myArm3', 'coords': [2, 9, ACyStart+3, ACyStart+4], 'type': 'editableLink-item', 'options': spellOptions },
        {'name': 'myArm4', 'coords': [2, 9, ACyStart+4, ACyStart+5], 'type': 'editableLink-item', 'options': spellOptions },
        {'name': 'myArm5', 'coords': [2, 9, ACyStart+5, ACyStart+6], 'type': 'editableLink-item', 'options': spellOptions },
        

        {'name': 'WeaTxt', 'coords': [1, 5, WeapyStart, WeapyStart+1], 'type': 'spellButton', 'options': {}  },
        {'name': 'myWeap1', 'coords': [1, 5, WeapyStart+1, WeapyStart+2], 'type': 'editableLink-item', 'options': spellOptions },
        {'name': 'myWeap2', 'coords': [1, 5, WeapyStart+2, WeapyStart+3], 'type': 'editableLink-item', 'options': spellOptions },
        {'name': 'myWeap3', 'coords': [1, 5, WeapyStart+3, WeapyStart+4], 'type': 'editableLink-item', 'options': spellOptions },
        {'name': 'myWeap4', 'coords': [1, 5, WeapyStart+4, WeapyStart+5], 'type': 'editableLink-item', 'options': spellOptions },
        {'name': 'myWeap5', 'coords': [1, 5, WeapyStart+5, WeapyStart+6], 'type': 'editableLink-item', 'options': spellOptions },

        {'name': 'PotTxt', 'coords': [1, 9, 32, 33], 'type': 'spellButton', 'options': {} },
        {'name': 'myPot1', 'coords': [1, 8, 33, 34], 'type': 'editableLink-item', 'options': spellOptions },
        {'name': 'myPot2', 'coords': [1, 8, 34, 35], 'type': 'editableLink-item', 'options': spellOptions },
        {'name': 'myPot3', 'coords': [1, 8, 35, 36], 'type': 'editableLink-item', 'options': spellOptions },
        {'name': 'myPot4', 'coords': [1, 8, 36, 37], 'type': 'editableLink-item', 'options': spellOptions },
        {'name': 'myPot5', 'coords': [1, 8, 37, 38], 'type': 'editableLink-item', 'options': spellOptions },       
        {'name': 'myPot6', 'coords': [1, 8, 38, 39], 'type': 'editableLink-item', 'options': spellOptions },  

        {'name': 'MagTxt', 'coords': [1, 9, 39, 40], 'type': 'spellButton', 'options': {} },
        {'name': 'myMag1', 'coords': [1, 9, 40, 41], 'type': 'editableLink-item', 'options': spellOptions },
        {'name': 'myMag2', 'coords': [1, 9, 41, 42], 'type': 'editableLink-item', 'options': spellOptions },
        {'name': 'myMag3', 'coords': [1, 9, 42, 43], 'type': 'editableLink-item', 'options': spellOptions },
        {'name': 'myMag4', 'coords': [1, 9, 43, 44], 'type': 'editableLink-item', 'options': spellOptions },
        {'name': 'myMag5', 'coords': [1, 9, 44, 45], 'type': 'editableLink-item', 'options': spellOptions },

        {'name': 'OthTxt', 'coords': [1, 9, OthStart+0, OthStart+1], 'type': 'spellButton', 'options': {} },
        {'name': 'myOth1', 'coords': [1, 9, OthStart+1, OthStart+2], 'type': 'editableLink-item', 'options': spellOptions },
        {'name': 'myOth2', 'coords': [1, 9, OthStart+2, OthStart+3], 'type': 'editableLink-item', 'options': spellOptions },
        {'name': 'myOth3', 'coords': [1, 9, OthStart+3, OthStart+4], 'type': 'editableLink-item', 'options': spellOptions },
        {'name': 'myOth4', 'coords': [1, 9, OthStart+4, OthStart+5], 'type': 'editableLink-item', 'options': spellOptions },
        {'name': 'myOth5', 'coords': [1, 9, OthStart+5, OthStart+6], 'type': 'editableLink-item', 'options': spellOptions },
        {'name': 'myOth6', 'coords': [1, 9, OthStart+6, OthStart+7], 'type': 'editableLink-item', 'options': spellOptions },
        {'name': 'myOth7', 'coords': [1, 9, OthStart+7, OthStart+8], 'type': 'editableLink-item', 'options': spellOptions },

        {'name': 'Sp1Txt', 'coords': [1, 3, SpellStart+0, SpellStart+1], 'type': 'spellButton', 'options': {} },
        {'name': 'Sp2Txt', 'coords': [5, 7, SpellStart+0, SpellStart+1], 'type': 'spellButton', 'options': {} },
        {'name': 'Sp3Txt', 'coords': [1, 3, SpellStart+11, SpellStart+12], 'type': 'spellButton', 'options': {} },
        {'name': 'Sp4Txt', 'coords': [5, 7, SpellStart+11, SpellStart+12], 'type': 'spellButton', 'options': {} },
        {'name': 'Sp5Txt', 'coords': [1, 3, SpellStart+22, SpellStart+23], 'type': 'spellButton', 'options': {} },
      
        {'name': 'mySp11', 'coords': [1, 5, SpellStart+1, SpellStart+2], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[11] },
        {'name': 'mySp12', 'coords': [1, 5, SpellStart+2, SpellStart+3], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[12] },
        {'name': 'mySp13', 'coords': [1, 5, SpellStart+3, SpellStart+4], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[13] },
        {'name': 'mySp14', 'coords': [1, 5, SpellStart+4, SpellStart+5], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[14] },
        {'name': 'mySp15', 'coords': [1, 5, SpellStart+5, SpellStart+6], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[15] },
        {'name': 'mySp16', 'coords': [1, 5, SpellStart+6, SpellStart+7], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[16] },
        {'name': 'mySp17', 'coords': [1, 5, SpellStart+7, SpellStart+8], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[17] },
        {'name': 'mySp18', 'coords': [1, 5, SpellStart+8, SpellStart+9], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[18] },
        {'name': 'mySp19', 'coords': [1, 5, SpellStart+9, SpellStart+10], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[19] },
        {'name': 'mySp20', 'coords': [1, 5, SpellStart+10, SpellStart+11], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[20] },

        {'name': 'mySp21', 'coords': [5, 9, SpellStart+1, SpellStart+2], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[21] },
        {'name': 'mySp22', 'coords': [5, 9, SpellStart+2, SpellStart+3], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[22] },
        {'name': 'mySp23', 'coords': [5, 9, SpellStart+3, SpellStart+4], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[23] },
        {'name': 'mySp24', 'coords': [5, 9, SpellStart+4, SpellStart+5], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[24] },
        {'name': 'mySp25', 'coords': [5, 9, SpellStart+5, SpellStart+6], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[25] },
        {'name': 'mySp26', 'coords': [5, 9, SpellStart+6, SpellStart+7], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[26] },
        {'name': 'mySp27', 'coords': [5, 9, SpellStart+7, SpellStart+8], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[27] },
        {'name': 'mySp28', 'coords': [5, 9, SpellStart+8, SpellStart+9], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[28] },
        {'name': 'mySp29', 'coords': [5, 9, SpellStart+9, SpellStart+10], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[29] },
        {'name': 'mySp30', 'coords': [5, 9, SpellStart+10, SpellStart+11], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[30] },

        {'name': 'mySp31', 'coords': [1, 5, SpellStart+12, SpellStart+13], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[31] },
        {'name': 'mySp32', 'coords': [1, 5, SpellStart+13, SpellStart+14], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[32] },
        {'name': 'mySp33', 'coords': [1, 5, SpellStart+14, SpellStart+15], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[33] },
        {'name': 'mySp34', 'coords': [1, 5, SpellStart+15, SpellStart+16], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[34] },
        {'name': 'mySp35', 'coords': [1, 5, SpellStart+16, SpellStart+17], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[35] },
        {'name': 'mySp36', 'coords': [1, 5, SpellStart+17, SpellStart+18], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[36] },
        {'name': 'mySp37', 'coords': [1, 5, SpellStart+18, SpellStart+19], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[37] },
        {'name': 'mySp38', 'coords': [1, 5, SpellStart+19, SpellStart+20], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[38] },
        {'name': 'mySp39', 'coords': [1, 5, SpellStart+20, SpellStart+21], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[39] },
        {'name': 'mySp40', 'coords': [1, 5, SpellStart+21, SpellStart+22], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[40] },

        {'name': 'mySp41', 'coords': [5, 9, SpellStart+12, SpellStart+13], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[41] },
        {'name': 'mySp42', 'coords': [5, 9, SpellStart+13, SpellStart+14], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[42] },
        {'name': 'mySp43', 'coords': [5, 9, SpellStart+14, SpellStart+15], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[43] },
        {'name': 'mySp44', 'coords': [5, 9, SpellStart+15, SpellStart+16], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[44] },
        {'name': 'mySp45', 'coords': [5, 9, SpellStart+16, SpellStart+17], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[45] },
        {'name': 'mySp46', 'coords': [5, 9, SpellStart+17, SpellStart+18], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[46] },
        {'name': 'mySp47', 'coords': [5, 9, SpellStart+18, SpellStart+19], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[47] },
        {'name': 'mySp48', 'coords': [5, 9, SpellStart+19, SpellStart+20], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[48] },
        {'name': 'mySp49', 'coords': [5, 9, SpellStart+20, SpellStart+21], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[49] },
        {'name': 'mySp50', 'coords': [5, 9, SpellStart+21, SpellStart+22], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[50] },

        {'name': 'mySp51', 'coords': [1, 5, SpellStart+23, SpellStart+24], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[51] },
        {'name': 'mySp52', 'coords': [1, 5, SpellStart+24, SpellStart+25], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[52] },
        {'name': 'mySp53', 'coords': [1, 5, SpellStart+25, SpellStart+26], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[53] },
        {'name': 'mySp54', 'coords': [1, 5, SpellStart+26, SpellStart+27], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[54] },            
        {'name': 'mySp55', 'coords': [1, 5 ,SpellStart+27, SpellStart+28], 'type': 'editableLink-spell', 'options': spellOptions, 'spellChecks': spellCheckArray[55] },
    ]

    // define the box structure
    const boxez = () => {
        
        const boxes_main = {  }

        var boxes_temp =  {
            // ...boxes_main = boxName: [1,21,1,2 ], boxStats: [1,8,2,8], boxStats2: [8,14,2,8], boxStats3: [14,21,2,8],
            boxClas: [1,7,2,4],
            boxRace: [1,7,4,6], boxGld: [7,9,4,6],  
            boxHei:  [1,5,6,8], boxNXP:  [5,7,6,8],boxXP:  [7,9,6,8], 

            boxStrA: [1,2,StatStart,StatStart+1], boxStrB: [2,4,StatStart,StatStart+1], boxStrC: [4,5,StatStart,StatStart+1],
            boxIntA: [1,2,StatStart+1,StatStart+2], boxIntB: [2,4,StatStart+1,StatStart+2], boxIntC: [4,5,StatStart+1,StatStart+2],
            boxWisA: [1,2,StatStart+2,StatStart+3], boxWisB: [2,4,StatStart+2,StatStart+3], boxWisC: [4,5,StatStart+2,StatStart+3],
            boxDexA: [5,6,StatStart,StatStart+1], boxDexB: [6,8,StatStart,StatStart+1], boxDexC: [8,9,StatStart,StatStart+1],
            boxConA: [5,6,StatStart+1,StatStart+2], boxConB: [6,8,StatStart+1,StatStart+2], boxConC: [8,9,StatStart+1,StatStart+2],
            boxChaA: [5,6,StatStart+2,StatStart+3], boxChaB: [6,8,StatStart+2,StatStart+3], boxChaC: [8,9,StatStart+2,StatStart+3],
            
            boxHP:   [7,9,12,14],
            boxAC:   [1,4,12,14], boxACb: [4,7,12,14],
            boxToHit: [3,6,14,16], boxMov: [1,3,14,16],
            
            boxAC1: [1, 2, ACyStart, ACyStart+1], boxAC2: [1, 2, ACyStart+1, ACyStart+2], boxAC3: [1, 2, ACyStart+2, ACyStart+3], boxAC4: [1, 2, ACyStart+3, ACyStart+4], boxAC5: [1, 2, ACyStart+4, ACyStart+5], boxAC6: [1, 2, ACyStart+5, ACyStart+6],

            boxArmTxt: [2, 9, ACyStart, ACyStart+1], boxArm2: [2, 9, ACyStart+1, ACyStart+2], boxArm3: [2, 9, ACyStart+2, ACyStart+3], boxArm4: [2, 9, ACyStart+3, ACyStart+4], boxArm5: [2, 9, ACyStart+4, ACyStart+5], boxArm6: [2, 9, ACyStart+5, ACyStart+6],

            boxWeaTxt: [1, 5, WeapyStart, WeapyStart+1], boxWea2: [1, 5, WeapyStart+1, WeapyStart+2], boxWea3: [1, 5, WeapyStart+2, WeapyStart+3], 
            boxWea4: [1, 5, WeapyStart+3, WeapyStart+4], boxWea5: [1, 5, WeapyStart+4, WeapyStart+5], boxWea6: [1, 5, WeapyStart+5, WeapyStart+6],
            boxWthTxt: [5, 6, WeapyStart, WeapyStart+1], boxWth2: [5, 6, WeapyStart+1, WeapyStart+2], boxWth3: [5, 6, WeapyStart+2, WeapyStart+3], 
            boxWth4: [5, 6, WeapyStart+3, WeapyStart+4], boxWth5: [5, 6, WeapyStart+4, WeapyStart+5], boxWth6: [5, 6, WeapyStart+5, WeapyStart+6],
            boxWdbTxt: [8, 9, WeapyStart, WeapyStart+1], boxWdb2: [8, 9, WeapyStart+1, WeapyStart+2], boxWdb3: [8, 9, WeapyStart+2, WeapyStart+3], 
            boxWdb4: [8, 9, WeapyStart+3, WeapyStart+4], boxWdb5: [8, 9, WeapyStart+4, WeapyStart+5], boxWdb6: [8, 9, WeapyStart+5, WeapyStart+6],
            boxWdgTxt: [6, 8, WeapyStart, WeapyStart+1], boxWdg2: [6, 8, WeapyStart+1, WeapyStart+2], boxWdg3: [6, 8, WeapyStart+2, WeapyStart+3], 
            boxWdg4: [6, 8, WeapyStart+3, WeapyStart+4], boxWdg5: [6, 8, WeapyStart+4, WeapyStart+5], boxWdg6: [6, 8, WeapyStart+5, WeapyStart+6],
            
            boxPon1: [8, 9, 33, 34], boxPon2: [8, 9, 34, 35], boxPon3: [8, 9, 35,36], boxPon4: [8, 9, 36,37], boxPon5: [8, 9, 37,38], boxPon6: [8, 9, 38,39],
            boxPotTxt: [1, 9, 32, 33], boxPot1: [1, 9, 33,34], boxPot2: [1, 9, 34,35], boxPot3: [1, 9, 35,36], boxPot4: [1, 9, 36,37], boxPot5: [1, 9, 37,38], boxPot6: [1, 9, 38,39],

            boxMagTxt: [1, 9, 39, 40], boxMag2: [1, 9, 40, 41], boxMag3: [1, 9, 41, 42], boxMag4: [1, 9, 42, 43], boxMag5: [1, 9, 43, 44], boxMag6: [1, 9, 44, 45],
            boxOthTxt: [1, 9, 45, 46], boxOth2: [1, 9, 46, 47], boxOth3: [1, 9, 47, 48], boxOth4: [1, 9, 48, 49], boxOth5: [1, 9, 49, 50], boxOth6: [1, 9, 50, 51],
            boxFolTxt: [1, 9, 51, 52], boxFol1: [1, 9, 52, 53], boxFol2: [1, 9, 53, 54],
            boxRacTxt: [7, 9, SpellStart-8, SpellStart-7], boxRac1: [1, 9, SpellStart-8, SpellStart],
            boxSavTxt: [1, 9, SavyStart, SavyStart+1], boxSav1: [1, 5, SavyStart+1, SavyStart+4], boxSav2: [5, 9, SavyStart+1, SavyStart+4],
            boxAbiTxt: [5, 9, SpellStart+22, SpellStart+23]
        }
        
        // Boxes for spells
        for (let col = 1; col <= 5; col++) {

            let colShift = 1
            let rowShift = 0

            if ( col > 2 ) { 
                colShift = 3;
                rowShift = 11;
             }
            if ( col > 4 ) { 
                colShift = 5;
                rowShift = 22;
             }
            
            boxes_temp['boxSpt' + col ] = [1 + (col - colShift) * 4, 3 + (col - colShift) * 4, SpellStart + rowShift, SpellStart+1 + rowShift];
            boxes_temp['boxSpq1' + col ] = [3 + (col - colShift) * 4, 4 + (col - colShift) * 4, SpellStart+ rowShift, SpellStart+1 + rowShift];
            boxes_temp['boxSpq2' + col ] = [4 + (col - colShift) * 4, 5 + (col - colShift) * 4, SpellStart+ rowShift, SpellStart+1 + rowShift];
            
            for (let row = 1; row <= 10; row++) {
                if ( col === 5 & row > 6) continue; // the final column has "More" insead of spells
                boxes_temp['boxS' + col + row] = [1 + (col - colShift) * 4, 5 + (col - colShift) * 4, row + SpellStart + rowShift, row + SpellStart + 1 +rowShift];
            }
        }
        
        return boxes_temp
    }

    // <button className='no-print' style={{position:'absolute',top: '16px', left: '120px', fontSize: '8px', width: '14px', paddingTop: '1', zIndex: 120 }}>S</button>

        
    // ---------- return html (jsx) ----------

    return (
        <>
            <div id="charsheet-scroll">
              <div id="charsheet-full" className="charsheetMobile">

                <FlexiGrid2
                      rowSize = '8px'
                      colSize = '36px'
                      id = {thisCharID+'-grid-full'}
                      classRoot = 'cf'
                      cells = { cells_layout_main }
                      cellsHTML = { cells_html }
                      data = { data }
                      onDataChange = { setData }
                      boxes = { boxez() }
                />

                <LoadSaveButton className='no-printy loadbutton-mobile' state={[data, setData, currentChar, setChar ]} spellCheckArray={spellCheckArray}/>

                {/* <Spell spellname={ currentSpell } spelldata={ currentSpellData } UItype={'mobile'}/> */}

              </div>
              <div id="footer"><br/><br /><br/><br /><br/>Advanced Dungeon Master by DXA Development</div>    
            </div>
        </>
    )

    };  // end of function

    export default CharsheetMobile

/* Example usage (from Charsheet05)
    <CharsheetMobile state={props.state} data={char1data} setdata={setChar1Data} charID='char1' />
    <CharsheetMobile state={props.state} data={char2data} setdata={setChar2Data} charID='char2' />
*/