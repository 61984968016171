import $ from 'jquery';

function getCharData(){

    // reads the character data in charsheet0 form and returns it as a json 
    var chardata={};
    var stats={};
    var chars={};
    var armor={};
    var weapons={};
    var potions={};
    var followers={};
    var magic={};
    var items={};

    var saves={};
    var spells={};
    var abilities={};

    // stats["str"] = document.getElementById("myStr").value
    stats["myStr"] = $("#myStr").val();
    stats["myInt"] = $("#myInt").val();
    stats["myWis"] = $("#myWis").val();
    stats["myDex"] = $("#myDex").val();
    stats["myCon"] = $("#myCon").val();
    stats["myCha"] = $("#myCha").val();
        stats["myStrBon"] = $("#myStrBon").val();
        stats["myIntBon"] = $("#myIntBon").val();
        stats["myWisBon"] = $("#myWisBon").val();
        stats["myDexBon"] = $("#myDexBon").val();
        stats["myConBon"] = $("#myConBon").val();
        stats["myChaBon"] = $("#myChaBon").val();

    chars["myMov"] = $("#myMov").val();
    chars["myHei"] = $("#myHei").val();
    chars["myWei"] = $("#myWei").val();

    chars["myClass"] = $("#myClass").val();
    chars["myLevel"] = $("#myLevel").val();
    chars["myXP"] = $("#myXP").val();
    chars["myRace"] = $("#myRace").val();
    chars["myAlign"] = $("#myAlign").val();
    
    // version 0.3 additions
    chars["myGold"] = $("#myGold").val();
    chars["myXPbonus"] = $("#myXPbonus").val();
    chars["myXPnext"] = $("#myXPnext").val();
    chars["myDeity"] = $("#myDeity").val();
    // end v0.3

    chars["myHPbase"] = $("#myHPbase").val();
    chars["myHPcurr"] = $("#myHPcurr").val();
    chars["myHPnote"] = $("#myHPnote").val();

    chars["myAC"] = $("#myAC").val();
    chars["myRear"] = $("#myRear").val();
    chars["mySide"] = $("#mySide").val();
    chars["myToHit"] = $("#myToHit").val();
    chars["myNatt"] = $("#myNatt").val();
        armor["myBaseAC"] = $("#myBaseAC").val();
        armor["myAb1"] = $("#myAb1").val();
        armor["myAb2"] = $("#myAb2").val();
        armor["myAb3"] = $("#myAb3").val();
        armor["myAb4"] = $("#myAb4").val();
        armor["myAb5"] = $("#myAb5").val();
        armor["myArm1"] = $("#myArm1").val();
        armor["myArm2"] = $("#myArm2").val();
        armor["myArm3"] = $("#myArm3").val();
        armor["myArm4"] = $("#myArm4").val();
        armor["myArm5"] = $("#myArm5").val();
    weapons["myWeap1"] = $("#myWeap1").val();
    weapons["myWeap2"] = $("#myWeap2").val();
    weapons["myWeap3"] = $("#myWeap3").val();
    weapons["myWeap4"] = $("#myWeap4").val();
    weapons["myWeap5"] = $("#myWeap5").val();
    weapons["myWth1"] = $("#myWth1").val();
    weapons["myWth2"] = $("#myWth2").val();
    weapons["myWth3"] = $("#myWth3").val();
    weapons["myWth4"] = $("#myWth4").val();
    weapons["myWth5"] = $("#myWth5").val();
    weapons["myWdb1"] = $("#myWdb1").val();
    weapons["myWdb2"] = $("#myWdb2").val();
    weapons["myWdb3"] = $("#myWdb3").val();
    weapons["myWdb4"] = $("#myWdb4").val();
    weapons["myWdb5"] = $("#myWdb5").val();
    weapons["myWdmg1"] = $("#myWdmg1").val();
    weapons["myWdmg2"] = $("#myWdmg2").val();
    weapons["myWdmg3"] = $("#myWdmg3").val();
    weapons["myWdmg4"] = $("#myWdmg4").val();
    weapons["myWdmg5"] = $("#myWdmg5").val();
        potions["myPot1"] = $("#myPot1").val();
        potions["myPot2"] = $("#myPot2").val();
        potions["myPot3"] = $("#myPot3").val();
        potions["myPot4"] = $("#myPot4").val();
        potions["myPot5"] = $("#myPot5").val();
        potions["myPot6"] = $("#myPot6").val();

        followers["myFol1"] = $("#myFol1").val();
        followers["myFol2"] = $("#myFol2").val();

        potions["myQpo1"] = $("#myQpo1").val();
        potions["myQpo2"] = $("#myQpo2").val();
        potions["myQpo3"] = $("#myQpo3").val();
        potions["myQpo4"] = $("#myQpo4").val();
        potions["myQpo5"] = $("#myQpo5").val();
        potions["myQpo6"] = $("#myQpo6").val();

    magic["myMag1"] = $("#myMag1").val();
    magic["myMag2"] = $("#myMag2").val();
    magic["myMag3"] = $("#myMag3").val();
    magic["myMag4"] = $("#myMag4").val();
    magic["myMag5"] = $("#myMag5").val();

        items["myOth1"] = $("#myOth1").val();
        items["myOth2"] = $("#myOth2").val();
        items["myOth3"] = $("#myOth3").val();
        items["myOth4"] = $("#myOth4").val();
        items["myOth5"] = $("#myOth5").val();
        // added in v0.4
        items["myOth6"] = $("#myOth6").val();
        items["myOth7"] = $("#myOth7").val();
        saves["mySbon7"] = $("#mySbon7").val();
        chars["myAge"] = $("#myAge").val();

    saves["mySav1"] = $("#mySav1").val();
    saves["mySav2"] = $("#mySav2").val();
    saves["mySav3"] = $("#mySav3").val();
    saves["mySav4"] = $("#mySav4").val();
    saves["mySav5"] = $("#mySav5").val();
    saves["mySbon1"] = $("#mySbon1").val();
    saves["mySbon2"] = $("#mySbon2").val();
    saves["mySbon3"] = $("#mySbon3").val();
    saves["mySbon4"] = $("#mySbon4").val();
    saves["mySbon5"] = $("#mySbon5").val();
    saves["mySbon6"] = $("#mySbon6").val();

        spells["myTsp1"] = $("#myTsp1").val();
        spells["myTsp2"] = $("#myTsp2").val();
        spells["myTsp3"] = $("#myTsp3").val();
        spells["myTsp4"] = $("#myTsp4").val();
        spells["myTsp5"] = $("#myTsp5").val();
        spells["myNsp1"] = $("#myNsp1").val();
        spells["myNsp2"] = $("#myNsp2").val();
        spells["myNsp3"] = $("#myNsp3").val();
        spells["myNsp4"] = $("#myNsp4").val();
        spells["myNsp5"] = $("#myNsp5").val();
        spells["mySp11"] = $("#mySp11").val();
        spells["mySp12"] = $("#mySp12").val();
        spells["mySp13"] = $("#mySp13").val();
        spells["mySp14"] = $("#mySp14").val();
        spells["mySp15"] = $("#mySp15").val();
        spells["mySp16"] = $("#mySp16").val();
        spells["mySp17"] = $("#mySp17").val();
        spells["mySp18"] = $("#mySp18").val();
        spells["mySp19"] = $("#mySp19").val();
        spells["mySp20"] = $("#mySp20").val();
        spells["mySp21"] = $("#mySp21").val();
        spells["mySp22"] = $("#mySp22").val();
        spells["mySp23"] = $("#mySp23").val();
        spells["mySp24"] = $("#mySp24").val();
        spells["mySp25"] = $("#mySp25").val();
        spells["mySp26"] = $("#mySp26").val();
        spells["mySp27"] = $("#mySp27").val();
        spells["mySp28"] = $("#mySp28").val();
        spells["mySp31"] = $("#mySp31").val();
        spells["mySp32"] = $("#mySp32").val();
        spells["mySp33"] = $("#mySp33").val();
        spells["mySp34"] = $("#mySp34").val();
        spells["mySp35"] = $("#mySp35").val();
        spells["mySp36"] = $("#mySp36").val();
        spells["mySp37"] = $("#mySp37").val();
        spells["mySp38"] = $("#mySp38").val();
        spells["mySp41"] = $("#mySp41").val();
        spells["mySp42"] = $("#mySp42").val();
        spells["mySp43"] = $("#mySp43").val();
        spells["mySp44"] = $("#mySp44").val();
        spells["mySp45"] = $("#mySp45").val();
        spells["mySp46"] = $("#mySp46").val();
        spells["mySp47"] = $("#mySp47").val();
        spells["mySp48"] = $("#mySp48").val();
        spells["mySp51"] = $("#mySp51").val();
        spells["mySp52"] = $("#mySp52").val();
        spells["mySp53"] = $("#mySp53").val();
        spells["mySp54"] = $("#mySp54").val();

    abilities["myAbi"] = $("#myAbi").val();
    abilities["myRabi"] = $("#myRabi").val();

    chardata["stats"] = stats;
    chardata["chars"] = chars;
    chardata["armor"] = armor;
    chardata["weapons"] = weapons;
    chardata["potions"] = potions;
    chardata["followers"] = followers;
    chardata["magic"] = magic;
    chardata["items"] = items;
    chardata["saves"] = saves;
    chardata["spells"] = spells;
    chardata["abilities"] = abilities;

    console.log(chardata);
    return chardata;

}

export default getCharData