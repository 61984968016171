import axios from 'axios';

async function SAVE_CHAR(postData, characterName = "Jack Squat", campaign = "Greyhawk20", setChar = '', version="0.4") {

    // save the character data in postData to dynamoDB

    console.log({ SaveChar: { name: characterName, campaign: campaign, version: version, chardata: postData } });

    // these are ENVIRONMENT VARIABLES & need to go somewhere else !!!

    const charsheetAPI = 'https://2gy03m48ej.execute-api.us-west-2.amazonaws.com/default/charsheet-access'
    const charsheetTable = 'charsheet-test'

    // confirm the name to save as
    // replace prompt with a 'drop-down' window

    var saveName = prompt("Save name:  ** CAUTION - ALL SAVES PERMANENT **", characterName);

    console.log('SAVE_CHAR Post Data', postData)

    if (saveName === null || saveName === "") {
        const txt = "User cancelled the prompt.";
        console.log(txt);
        return 'User Cancelled'; }

    // SHORTCUTS for our favorite characters

    if (saveName === 'Lanora') { saveName = 'Lanora Snugglebottom'; } 
    if (saveName === 'Yarg') { saveName = 'Yarg Smashskull'; } 
    if (saveName === 'Gerald') { saveName = 'Gerald the Bold'; }
    if (saveName === 'Sylonnus') { saveName = 'Sylonnus Transvexus'; }

    const API_URL = charsheetAPI + '?TableName=' + charsheetTable + '&campaign=' + campaign + '&name=' + saveName;
    
    let res = await axios({
        host: charsheetAPI,
        method: 'PUT',
        url: API_URL,
        headers: { 'content-type': 'text/plain' }, 
        data: { version: version, chardata: postData },   // this is the 'body' in AWS
      });

    // error checking needed here !!  
    console.log(res);
    if (setChar !== '') {
        setChar(saveName);
    }
}

export default SAVE_CHAR